import React from 'react';
import { useTheme } from 'styled-components';
import LogoWithText from '@Components/atoms/LogoWithText/LogoWithText';
import { PropsWithClassName } from '@Components/helper';
import { Routes } from '@Components/Routes';
import Link from '@Helpers/i18n/Link';
export enum LogoVariant {
  LogoAndWords,
  Logo,
}
export interface Props extends PropsWithClassName {
  height: number;
  variant?: LogoVariant;
  isLink?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
}
const Logo = (props: Props): React.ReactElement => {
  const {
    className,
    dataTestId,
    height,
    variant = LogoVariant.LogoAndWords,
    isLink = true,
    isHeader = false,
    onClick
  } = props;
  const theme = useTheme();
  const renderLogoIcon = () => {
    return <div className={props.className} data-testid={props.dataTestId ?? 'logo-without-text-root'} data-sentry-component="renderLogoIcon" data-sentry-source-file="Logo.tsx">
        <svg height={height} viewBox="0 0 554 554" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Logo.tsx">
          <path d="M139.069 9.04533H414.238C486.243 9.04533 544.615 67.4172 544.615 139.423V414.591C544.615 486.596 486.243 544.968 414.238 544.968H139.069C67.0637 544.968 8.69182 486.596 8.69182 414.591V139.423C8.69182 67.4172 67.0637 9.04533 139.069 9.04533Z" stroke={theme.palette.text.themePrimary} strokeWidth="17.3836" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
          <line x1="112.321" y1="171.315" x2="112.321" y2="395.557" stroke={theme.palette.text.themePrimary} strokeWidth="20.7197" strokeLinecap="round" data-sentry-element="line" data-sentry-source-file="Logo.tsx" />
          <circle cx="310.895" cy="286.432" r="130.076" stroke={theme.palette.text.themePrimary} strokeWidth="20.5767" data-sentry-element="circle" data-sentry-source-file="Logo.tsx" />
        </svg>
      </div>;
  };
  const renderLogo = () => {
    switch (variant) {
      case LogoVariant.Logo:
        return renderLogoIcon();
      case LogoVariant.LogoAndWords:
      default:
        return <LogoWithText height={height} isHeader={isHeader} />;
    }
  };
  const renderLogoWithLink = () => {
    if (!isLink) {
      return renderLogo();
    }
    return <Link href={Routes.Home} onClick={onClick} passHref aria-label="navigation-main-logo" data-sentry-element="Link" data-sentry-component="renderLogoWithLink" data-sentry-source-file="Logo.tsx">
        {renderLogo()}
      </Link>;
  };
  return <div className={className} data-testid={dataTestId ?? 'logo-root'} data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      {renderLogoWithLink()}
    </div>;
};
export default Logo;